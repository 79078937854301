import React from 'react';
import ReactGA from 'react-ga4';

import {About, Footer, Header, Skills, Testimonial, Work} from './container'
import { Navbar } from './components';
import './App.scss'
const App = () => {
  ReactGA.initialize('GTM-K5SLGVTS');

  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />
    </div>
  );
};

export default App;